module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The Bennett Law Center","short_name":"The Bennett Law Center","start_url":"/","background_color":"#206085","theme_color":"#206085","display":"standalone","icon":"src/assets/site-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8a4857ef133f1ac33abb267868df1d00"},
    },{
      plugin: require('../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-179417872-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-theme-i18n/gatsby-browser.js'),
      options: {"plugins":[],"defaultLang":"en","configPath":"/opt/build/repo/i18n/config.json"},
    },{
      plugin: require('../node_modules/gatsby-theme-i18n-react-intl/gatsby-browser.js'),
      options: {"plugins":[],"defaultLocale":"./i18n/react-intl/en.json"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
